@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Open+Sans:wght@300;400;500;600;700&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');


* {
   box-sizing: border-box;
   font-family: 'Work Sans', sans-serif;
}

body {
   font-family: 'Work Sans', sans-serif;
}

.sidebar {
   background-color: #FAFAFA;
   /* height: 100vh; */
   height: 100%;
   padding-top: 100px;
   padding-left: 30px;
   padding-right: 30px;
   position: fixed;
}

.fixedToTop {
   /* position: fixed; */
   /* padding-top: 150px; */
}

.sidebar ul li {
   /* display: flex; */
   list-style: none;
   width: 100%;
   padding: 10px 20px;
}

.headerDropDown {
   padding: 10px 20px
}

.headerDropDown li {
   /* display: flex; */
   list-style: none;
   width: 100%;
   padding: 5px 0px;
}

.sidebar ul li a,
.headerDropDown li a {
   display: flex;
   text-decoration: none;
   font-size: 16px;
   color: #000;
   font-weight: 500;
   font-family: 'Work Sans', sans-serif;
}

.sidebar ul li:hover {
   /* background-color: #CDF463; */
   background-color: #45B4A7;
   border-radius: 10px;
   color: #ffffff
}

.sidebar ul li a span {
   margin-right: 14px;
}

.sidebar ul .activeNav a {
   color: #fff;
}

.sidebar ul .activeNav a span img,
.sidebar ul .activeNav a span svg path {
   color: #fff;
   fill: #fff;
}

.pLO {
   padding-left: 0;
}

.marginBottom {
   margin-bottom: 12px;
}

.mainHeader {
   background-color: #fff;
   padding: 20px 24px;
   margin-top: 46px;
   justify-content: space-between;
   align-items: center;
   border-radius: 16px;
   margin-bottom: 20px;
}

.imgContainer {
   width: 45px;
   height: 45px;
   transform: rotate(0.059deg);
   color: #BFBFBF;
}

.imgContainer img {
   width: 100%;
   height: 100%;
}

.headerH1 {
   display: flex;
}

.headerH1 span {
   margin-left: 25px;
   border-radius: 100px;
   color: #fff;
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   /* 133.333% */
   letter-spacing: 0.6px;
   font-family: 'Work Sans', sans-serif;
   padding: 5px 15px;
}

.activeNav {
   border-radius: 10px;
   /* background: #CDF463; */
   background: #45B4A7;

}



.userActive {
   background-color: #27AE60;
}

.userPending {

   background-color: #F2994A;
}

.userReview {
   background-color: #EB5757;
}

.userCompleted {
   background-color: #367FFF;
}

.userStarted {
   background-color: #DEFFDD;
}

.go-profile {
   border-left: 1px solid #C2C2C2;
   padding-left: 20px;
}

.profile-inner {
   display: flex;
}

.oneRow {
   display: flex;
   align-items: center;
   justify-content: center;
}

.myProfile {
   margin-left: 16px;
}

.bell,
.floaterRight {
   float: right;
}

.line05 {
   line-height: 0.9;
}

.line09 {
   line-height: 0.9;
}

.ncol-2 {
   width: 19.666667% !important;
}

.ncol-10 {
   width: 80.333333% !important;
}

.col-20 {
   width: 20% !important;
}

.col-17 {
   width: 17% !important;
}

.dash-card {
   border-radius: 8px;
   border: 1px solid #EFF4FA;
   background: #FFF;
   padding: 20px 15px 10px 15px;
}

.dash-card img {
   margin-left: 4px;
}

.has-search {
   width: 80%;
   border-radius: 16px;
   background-color: #fff;
   border-radius: 16px;
}


.has-search .form-control-feedback {
   position: absolute;
   z-index: 2;
   display: block;
   width: 16px;
   height: 16px;
   flex-shrink: 0;
   line-height: 16px;
   margin-left: 16px;
   margin-top: 15px;
   text-align: center;
   pointer-events: none;
   color: #aaa;
}

.has-search .searchMyBox {
   padding-left: 40px;
}

.searchMyBox::placeholder {
   color: #404040;
   /* font-family: 'Open Sans', sans-serif; */
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 150%;
   /* 24px */
   letter-spacing: 0.5px;
}

.form-control1 {
   display: block;
   width: 100%;
   padding: 0.6rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   background-color: #fff;
   background-clip: padding-box;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   border-radius: 16px;
   border: 1px solid #fff;
}

.mb-20 {
   margin-bottom: 20px;
}

.mb-30 {
   margin-bottom: 30px;
}

.sortedBy {
   display: inline-flex;
   text-decoration: none;
   /* float: inline-end; */
}

.left12 {
   margin-left: 12px;
}

.left200 {
   margin-left: 200px;
}

.relativePosition {
   position: relative;
}

.dropdownLi {
   padding: 0px;
   min-width: 100px;
   position: absolute;
   z-index: 999;
   background-color: #fff;
}

.cursor-pointer {
   cursor: pointer;
}

.dropdownLi li {
   list-style: none;
   padding: 5px 15px;
   color: #000;
   font-family: 'Work Sans', sans-serif;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   text-transform: capitalize;
   border-radius: none;

   /* 150% */
}

.dropdownLi li:last-child {
   border-bottom: none;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

.dropdownLi li:first-child {
   border-bottom: none;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
}

.dropdownLi li:hover {
   background-color: #EFF4FA;
}

.myTable {
   background-color: #fff;
   border-radius: 10px;
   /* padding: 5px 0; */
   position: relative;
}

.myTable14 {
   /* min-height: 500px; */
   white-space: nowrap;
   overflow-x: scroll;
}

.myTable14::-webkit-scrollbar {
   display: none;
}

.bg-color-second {
   background-color: #EFF4FA;
}



.table-head-pad {
   padding: 15px;
}

.table-tr {
   border-bottom: 1px solid #EFF4FA;
}



tbody,
td,
tfoot,
th,
thead,
tr,
.table-text {
   padding: 15px 30px;
}

th {
   color: #8F9BB3;
   font-size: 15px;
   font-style: normal;
   font-weight: 600;
   line-height: 16px;
   /* font-family: 'Open Sans', sans-serif; */
}

.tdclass1 {
   color: #222B45;
   /* font-family: 'Work Sans', sans-serif; */
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   /* 150% */
}

.tdclass2 {
   color: #8F9BB3;
   /* font-family: 'Open Sans', sans-serif; */
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
}

.tdclass3 {
   color: #61646B;
   font-family: 'Work Sans', sans-serif;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   /* 150% */
   letter-spacing: 0.2px;
}

.tdclass4 {
   color: #222B45;
   font-family: 'Work Sans', sans-serif;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   /* 150% */
   letter-spacing: 0.16px;
}

.tableGrid {
   display: grid;
}

.pd-l-0 {
   padding-left: 0;
}

.innerNavigation {
   width: 100%;

}

.innerNavigation,
.boderNavigation {
   display: inline-block;
   /* padding-left: 0; */
}

.innerNavigation2 {
   display: inline-flex;
}

.innerNavigation2 ul li {
   display: inline-flex;
   padding: 10px 20px;
}

.innerNavigation ul,
.innerNavigation2 ul {
   background-color: #fff;
   padding: 10px 20px;
   border-radius: 20px;

}

.singleSessionNav {
   width: 100%;
}

.singleSessionNav ul {
   width: 100%;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.innerNavigation ul li {
   /* display: inline-flex; */
   display: block;
   padding: 10px 20px;
   margin-bottom: 10px;
}

.boderNavigation ul {
   padding-left: 0;
}

.boderNavigation ul li {
   background-color: #fff;
   display: inline-flex;
   padding: 10px 20px;
   margin-right: 10px;
   border-radius: 30px;
}

.boderNavigation ul li:hover,
.innerNavigation ul li:hover {
   background-color: #FAFAFA;
   border-radius: 10px;
}

.borderActive {
   border-bottom: 1px solid #27AE60;
}

.backgroundNormal {
   background-color: #fff;
   border-radius: 10px;

}

.backGroundActive:not(:hover) {
   background-color: #26B893;
   border-radius: 10px;
   color: #fff;
}


.profileDropdown {
   float: inline-end;
   margin-top: -28px;
   /* margin-right: -25px; */
}

.n-20 {
   margin-top: -20px;
}

.imgContainer2 {
   width: 65px;
   height: 65px;
   border-radius: 65px;
   color: #BFBFBF;
   overflow: hidden;
   min-width: 65px;
}

.imgContainer2 img {
   width: 100%;
   /* height: 100%; */
}

.line14 {
   line-height: 1.4;
}

.mln-8 {
   margin-left: 8px;
}

.PageHeaderWrapper {
   margin-top: 30px;
}

.emptyTableClass {
   display: grid;
   justify-content: center;
   align-items: center;
   padding: 100px 0
}

.emptyTableClass img {
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 20px;
}

.cursor-hover:hover {
   background-color: #FAFAFA;
}

.bookedTable {
   /* display: flex; */
}

.dropDowns {
   display: flex;

}

.dropDowns {
   margin-top: -37px;
   margin-right: 20px;
   float: right;
}

.dropDownsYear {
   margin-left: 10px;
}

.go15mp {
   padding: 15px 15px;
}

.bookedGraph {
   height: auto;
   padding: 10px;
}

.border-radius-5 {
   border-radius: 5px;
}

.border-radius-20 {
   border-radius: 20px;
}

.innerNavigation ul {
   background-color: #EFF4FA;
   margin-left: 10px;
   margin-top: 10px;
   margin-bottom: 10px;
}

.newSelect select {
   padding: 10px 20px;
   align-items: center;
   background-color: #fff;
   color: #000;
   border-radius: 5px;
   border: none;
}

.loginForm {
   width: 20%;
   margin: auto;
   position: absolute;
   top: 20%;
   /* bottom: 50%; */
   left: 0;
   right: 0;
}

.containerForm {
   display: grid;
}

.containerForm input {
   width: 100%;
   border-radius: 12px;
   padding: 10px 10px 10px 20px;
   background-color: #fff;
   border: 1px solid #45B4A7;
   padding-left: 45px;
}

.containerForm input:focus {
   border: 1px solid #45B4A7;
}


.containerForm img {
   position: absolute;
   margin-top: 10px;
   margin-left: 10px;
   width: 24px;
   height: 24px;
}


.mt57 {
   margin-top: 57px;
}

.loginBtn {
   padding: 12px 20px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 24px;
   background: #45B4A7;
   border: none;
}

.loginBackground {
   background-color: #fff;
   width: 100%;
   height: 100vh;
}

.profileActive {
   padding: 5px 20px;
   border-radius: 100px;
   color: #fff;
}

.greenBackground {
   border-radius: 20px;
   background: #DEFFDD;
   width: 80%;
   display: flex;
   padding: 10px;
}

.greenBackgroundMagic {
   /* width: 80%; */
   display: flex;
   padding: 10px;
   /* justify-content: space-around; */
}

.borderRight {
   border-right: 1px solid #AFB1B6;
}

.alignCenter {
   align-items: center;
   padding: 15px;
}

.ml50 {
   margin-left: 40px;
}

.newSelect2 select {
   padding: 15px 20px;
   align-items: center;
   background-color: #FAFAFA;
   color: #000;
   border-radius: 16px;
   border: none;
   padding-right: 100px;
}

.newSelect2 select::before {
   margin-right: 10px;
}

.profileFilterBtn {
   /* padding: 15px 40px; */
   background-color: #367FFF;
   border-radius: 100px;
   margin-left: 20px;
   border: none;
}

/* .goRightALittle {
   float: inline-end;
   margin-right: 26px;
} */

.borderBottomProfile {
   border-bottom: 1px solid #F2F2F2;
   padding-bottom: 37px;
}

.mt27 {
   margin-top: 27px;
}

.mt20 {
   margin-top: 20px;
}

.spaceRounded {
   justify-content: space-between;
   padding-right: 30px;
   margin-bottom: 20px;
}

.bgFilterWhite {
   background-color: #fff;
   padding: 10px 20px;
   border-radius: 16px;
}

.filterDropIt {
   padding: 20px;
   border-radius: 20px;
}

.filterForm {
   display: grid;
}

.filterForm input,
.filterForm select {
   width: 100%;
   border-radius: 12px;
   padding: 10px 10px 10px 10px;
   background-color: #FAFAFA;
   border: 1px solid #CCC;
}

.filterForm input:focus {
   /* border: 1px solid #45B4A7; */
}

.dropdownMain {
   min-width: 335px;
   padding: 20px;
   margin-top: 25px;
   border-radius: 16px;
   /* box-shadow: 3px 1px 3px 3px #eee */
   box-shadow: 2px 1px 2px 1px #eee
}

.borderRadius8 {
   border-radius: 8px;
}

.mt13 {
   margin-top: 13px;
}

.filterForm span {
   border-radius: 12px;
   padding: 10px 10px 10px 10px;
   background-color: #FAFAFA;
   border: 1px solid #FAFAFA;
   width: 80%;
   display: flex;
}

.filterForm input {
   padding-top: 0;
   padding-bottom: 0;
}

.imgContainerChat {
   border-radius: 10px;
   width: 40px;
   height: 40px;
   background-color: #222B45;
}

.borderBottomProfileChat {
   border-bottom: 1px solid #F2F2F2;
   /* padding-bottom: 37px; */
}

.borderBottomService {
   border-bottom: 2px solid #AFB1B6;
   padding-bottom: 20px;
   padding-top: 20px;
}

.line01 {
   line-height: 0.1;
}

.onlineOrNot {
   width: 10px;
   height: 10px;
   background-color: #68D391;
   border-radius: 50%;
   display: inline-block;
   margin-right: 7px;
}

.mb-n2 {
   margin-bottom: 2px;
}

.pd24 {
   padding: 24px;
}

.mr16 {
   margin-right: 16px;
}

.ml16 {
   margin-left: 16px;
}

.chatContainer {
   border-radius: 5px;
   padding: 10px;
   margin: 10px 0;
   display: flex;
}



.chatContainer::after {
   content: "";
   clear: both;
   display: table;
}

.chatContainer img {
   float: left;
   max-width: 60px;
   width: 100%;
   margin-right: 20px;
   border-radius: 50%;
}

.chatContainer img.right {
   float: right;
   margin-left: 20px;
   margin-right: 0;
}

.myChat {
   margin-top: auto;
   margin-bottom: auto;
   padding: 8px 16px;
   border-radius: 12px;
   background: #F1F1F1;
   max-width: 400px;
}

.myChatRight {
   padding: 16px 8px;
}

.uploadIcon {
   margin-top: auto;
   margin-bottom: auto;
}

.chatTextArea {
   border-radius: 12px;
   padding: 10px 20px;
   border: 2px solid var(--gray-gray-300, #E2E8F0);
   background: var(--White, #FFF);
   width: 100%;
}

.mt140 {
   margin-top: 140px;
}

.chatBox img {
   position: absolute;
   right: 50px;
   margin-top: 12px;
}

.mtb24 {
   margin-top: 24px;
   margin-bottom: 24px;
}

.goML801 {
   margin-top: auto;
   margin-bottom: auto;
}

.goML80 {
   /* margin-left: 5px; */
}

.pd14 {
   padding: 10px 20px;
}

.pdlr {
   padding-left: 20px;
   padding-right: 20px;
}

.w-70 {
   width: 70%;
}

.mb-31 {
   margin-bottom: 31px;
}

.blueBackgroundFeedback {
   border-radius: 16px;
   background: var(--blue-shade, #D3E6FF);
   width: 80%;
   display: flex;
   padding: 10px;
   /* padding: 20px 20px 20px 0px; */
   /* justify-content: center; */
}

.mt-n10 {
   margin-top: -10px;
}

.myRatings {
   /* margin-left: 10px; */
   margin-top: 10px;
}

.myRatings .checked {
   color: #367FFF;
}

.myRatings span {
   padding: 5px;
}

.myRatings span i {
   color: transparent;
   border: 1px solid #367FFF;
}

.questionFeedback {
   border-bottom: 1px solid #EFEFF0;
   padding-bottom: 15px;
}

.feedbackratings {
   width: 60%;
}

.feedbackBorderBottom {
   border-bottom: 1px solid#AFB1B6;
   padding: 20px;
   width: 90%;
}

.popupClass {
   text-align: center;
}

.popupText {
   max-width: 70%;
   margin-left: auto;
   margin-right: auto;
}

.popColor1 {
   background-color: #45B4A7;
}

.popColor2 {
   background-color: #E8EFFF;
}

.text-pop {
   color: #45B4A7;
}

.max400 {
   min-width: 400px;
}

.max900 {
   min-width: 900px;
}

.max700 {
   min-width: 700px;
}

.max600 {
   min-width: 600px;
}

.with60 {
   width: 60%;
}

.profileSelect .newSelect2 {
   display: flex;
}

.profileBTN {
   width: 143px;
   /* height: 56px; */
   padding: 12px 20px;
}

.educationTableHead .bg-color-second {
   background-color: transparent;
}

.educationTableHead table {
   margin-left: -28px;
}

.educationImage {
   width: 226px;
   height: 136px;
   border-radius: 10px;
   text-align: center;
}

.educationImage img {
   width: 100%;
   height: 100%;
}

.timeLineImage {
   width: 25.29px;
   height: 24px;
   border-radius: 25.9px;
}

.timeLineImage img {
   width: 100%;
   height: 100%;
}

.width70 {
   width: 70%;
}

.timeLineCategory {
   border-radius: 10px;
   background: #F2F2F2;
   padding: 5px 10px;
}

.timeLineCategory2 {
   margin-top: auto;
   margin-bottom: auto;
}

.newServiceBtn {
   right: 0;
   position: absolute;
   margin-right: 42px;
   padding: 10px 20px;
   border-radius: 10px;
}

.myspaceRounded {
   justify-content: space-around;
}

.editServiceBtn {
   padding: 10px 10px;
   border-radius: 10px;
   background: #367FFF;
   border: none;
   width: 100%;
   justify-content: space-between;
}

.alignDivCenter {
   align-items: center;
}

.textDecor {
   text-decoration: none;
}

.popImg {
   position: absolute;
   /* right: 0; */
   /* margin-right: 30px; */
   margin-left: 13%;
}

.my-form-control,
.my-form-control select {
   height: 50px;
}

.dropdownMain22 {
   margin-top: -200px;
}

.textareaclass {
   width: 100%;
   border-radius: 12px;
   border: 0px solid var(--brand-blue, #367FFF);
   background: var(--surface, #FAFAFA);
   height: 121px;
   padding: 10px 10px 10px 20px;
}

.mlr-auto {
   margin-left: auto;
   margin-right: auto;
}

.dropdownMainNew {
   padding: 20px;
   border-radius: 16px;
   box-shadow: 2px 1px 2px 1px #eee;
}

.borderRadis100 {
   border-radius: 100px;
}

.sessionSingleBg {
   border-radius: 100px;
   background: #B9EFE3;
   padding: 10px;
}

.smpr {
   padding-right: 50px;
}

.smpl {
   padding-left: 50px;
}

.myMarginAutoTB {
   margin-top: auto;
   margin-bottom: auto;
}

.starMargin span {
   margin-right: 5px;
}

.mtTop0 {
   margin-top: 0;
}

.hr-fix {
   margin-top: 16px;
   margin-bottom: 16px;
   background-color: #EFEFF0;

}

.dashboardUserCard {
   margin-left: 15px;
   margin-bottom: 30px;
   padding: 30px;
   border-radius: 16px;
   background: var(--white, #FFF);
   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.profileActive22 {
   padding: 5px 11px;
   border-radius: 100px;
   color: #fff;
   font-size: 10px;
   margin-right: 5px;
}

.scrolling-wrapper {
   overflow-x: scroll;
   position: relative;
   white-space: nowrap;
   width: 100%;
}

.col30 {
   width: 30% !important;
   display: inline-block;
}

.pdrr {
   padding-right: 5px;
}

.scrollez::-webkit-scrollbar {
   appearance: none;
   width: 3px;
   display: none;
   background-color: #fff;
}

.scrollez::-webkit-scrollbar-thumb {

   background-color: #ccc;

}

.scrollez::-webkit-scrollbar-track {

   background-color: #eee;

}

.paginationwrapper {
   margin: 0 auto;
   display: block;
   width: 960px;
   padding-bottom: 30px;
}

.mypagination {
   margin: 0;
   padding: 0;
   text-align: center
}

.mypagination li {
   display: inline;
   padding-right: 20px;
}

.mypagination li {
   display: inline-block;
   text-decoration: none;
   padding: 1px 10px;
   color: #000
}

/* Active and Hoverable Pagination */
.mypagination li {
   border-radius: 5px;
   -webkit-transition: background-color 0.3s;
   transition: background-color 0.3s
}

.mypagination li.active {
   background-color: #45B4A7;
   color: #fff
}

.mypagination li:hover:not(.active) {
   background-color: #ddd;
}

.myTableHeight {
   min-height: 500px;
   background-color: #fff;
}

.m-color-red {
   margin-top: 14px;
   margin-left: 50%;
   color: red
}

.field-icon {
   float: right;
   margin-left: -25px;
   margin-top: -28px;
   position: relative;
   z-index: 2;
   margin-right: 10px;
}

.containerForm2 span {
   /* position: absolute; */
   margin-top: -43px;
   margin-right: 25px;
   width: 24px;
   height: 24px;
}

.chartjs-tooltip {
   position: absolute;
}

.chartjs-tooltip-body-item td span {
   color: #000
}

.chart-wrapper canvas {
   max-height: 150px;
}

.dropDownsYear select {
   border-radius: 5px;
   /* color: #AFB1B6; */
   /* font-size: 10px; */
   /* padding: 10px; */
}

.avail img {
   width: 20px;
}

.mb00 {
   margin-bottom: 0px;
}

.qRestore {
   width: 65%;
}

.containerSticky {
   top: 0;
   position: sticky;

}

.chartjs-tooltip {
   top: 30px !important;
   /* display: none; */
}

.chartjs-tooltip table {
   display: inline-flex;
}

/* .chartjs-tooltip table thead {
   padding: 15px 0px !important;
}

.chartjs-tooltip table tbody {
   padding: 15px 0px !important;
}

.chartjs-tooltip table tbody tr td {
   padding: 15px 0px !important;
} */

.chartjs-tooltip table>* {
   padding: 0 !important;
}

.chartjs-tooltip table td,
.chartjs-tooltip table th {
   padding: 0 !important;
}

.chartjs-tooltip table th {
   /* margin-top: 5px !important; */
   padding-top: 5px !important;
   padding-right: 5px !important;
}

.textDecor {
   text-decoration: none;
}

.newHeight {
   max-height: 700px;

}

.newSticky {
   position: sticky;
   top: 0;
   height: 100%;
   max-height: 700px;
   overflow: auto;
}

.myNavDecor {
   width: 150px;
}

.imageClassWidth {
   margin-left: auto;
   margin-right: auto;
}

.imageClassWidth .imgContainer2 {
   margin-left: auto;
   margin-right: auto;
}

.marginBTM5 {
   margin-bottom: 5px;
}

.assessmentSelect {
   height: 40px;
   border-radius: 10px;
   padding: 8px;
   width: 100%;
   border: 1px solid #45b4a7 !important
}

.newTextarea {
   width: 100%;
   border: 1px solid #45b4a7;
   padding: 10px;
   height: 70px;
   border-radius: 10px;
}

.paddin {
   /* border-radius: 10px; */
   padding: 20px;
   border-bottom: 1px solid gainsboro;
}

.paddin2 {
   /* border-radius: 10px; */
   padding: 20px;
   border-bottom: none;
}

.go10 {
   font-size: 12px;
}

.p-l-0 input {
   padding-left: 10px;
}

.loginBtn2 {
   padding: 6px 13px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   background: #45B4A7;
   border: none;
}

.mleft19 {
   margin-left: 13%;
}

.sidebar ul li a:hover {
   color: #ffffff
}

.service-singleitem {
   padding: 10px;
   border-radius: 5px;
   /* box-shadow: 0px 0px 4px #ccc; */
   background-color: #f4f8f8;
   height: 90%;
}

.service-singleitem .service-namm {
   text-transform: capitalize;
   font-weight: 500;
}

.service-area h5 {
   text-transform: capitalize;
   margin-top: 2rem;
   margin-bottom: 1rem;
}

.service-singleitem .status-row {
   display: flex;
   justify-content: space-between;
   align-self: flex-end;
   font-size: 14px;
}

.innerNavigation ul {
   padding: 10px;
}