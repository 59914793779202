@media screen and (min-width:991px) {
   .provider-sidebar {
      position: sticky;
      top: 0;
      height: 100%;
   }
}

@media only screen and (max-width: 1300px) {
   .line05 {
      line-height: 1;
   }

   .qRestore {
      width: 60%;
   }

   .col30 {
      width: 45% !important;
   }

   .sidebar {
      padding-left: 6px;
      padding-right: 6px;
   }

   .sidebar ul li {
      padding: 7px 9px;
   }

   .dash-card {
      padding: 15px 10px 5px 10px;
   }

   .go-profile {
      padding-left: 10px;
   }

   .myProfile {
      margin-left: 10px;
   }

   .profileDropdown {
      margin-right: -10px;
      margin-top: -25px;
   }

   .col-20 {
      width: 28% !important;
      margin-bottom: 20px;
   }

   .col-17 {
      width: 24% !important;
      margin-bottom: 15px;
   }

   /* .innerNavigation ul {
      margin-left: 6px;
   }

   .innerNavigation ul li {
      padding: 7px 6px;
   } */

   .innerNavigation ul,
   .innerNavigation2 ul {
      /* padding: 10px 40px; */
   }

   .greenBackground {
      display: block;
      width: 100%;
   }

   .borderRight {
      border-bottom: 1px solid #AFB1B6;
      border-right: none;
   }

   .newSelect2 select {
      margin-bottom: 20px;
   }

   .profileFilterBtn {
      width: 100%;
      margin-left: 0;
   }

   .loginForm {
      width: 50%;
   }

   .with60,
   .width70 {
      width: 100%;
   }

}

@media only screen and (max-width: 1000px) and (min-width: 500px) {
   .sessionSingleBg {
      padding: 5px;
   }


   .qRestore {
      width: 60%;
   }

   .with60,
   .width70 {
      width: 100%;
   }

   .loginForm {
      width: 80%;
   }

   .sidebar ul li a {
      font-size: 10px;
   }

   .sidebar ul li a span {
      margin-right: 5px;
   }

   .sidebar ul li a span svg,
   .sidebar ul li a span img {
      width: 15px;
      height: 15px;
   }

   .containerBody {
      padding-left: 5px;
      padding-right: 5px;
   }

   .headerH1 div {
      font-size: 13px;
   }

   .col-20 {
      width: 32% !important;
   }

   .col-17 {
      width: 32% !important;
   }

   .spaceRounded {
      padding-right: 0;
   }

   .goRight {
      margin-left: 20px;
   }

   .bookedTable {
      padding-left: 20px;
      padding-right: 20px;
   }

   /* .goRightALittle {
      float: left;
   } */

   .profileSelect .newSelect2 {
      display: inline-flex;
   }


}

@media only screen and (max-width: 991px) and (min-width: 500px) {
   .col30 {
      width: 60% !important;
   }

   .floaterRight2 {
      float: left !important;
   }

   .col-20 {
      width: 50% !important;
      margin-bottom: 20px;
   }

   .gohidden22 {
      display: none;
   }

   .innerNavigation ul {
      display: inline-flex;
      width: 98%;
      overflow: scroll;
   }

   .innerNavigation ul li {
      margin: 10px;
      padding: 8px 8px;
   }

}

@media only screen and (max-width: 700px) and (min-width: 450px) {
   .col30 {
      width: 90% !important;
   }

   .col-20 {
      width: 100% !important;
      margin-bottom: 20px;
   }

   .gohidden {
      display: none;
   }

   .sidebar ul li {
      /* width: 28%; */
   }

   .ncol-22 {
      width: 15% !important;
   }

   .go15mp {
      padding: 15px 0px;
   }


}